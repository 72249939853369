import React from 'react';


import EnterpriseCard from './EnterpriseCard';
import ProCard from './ProCard';
import CommunityCard from './CommunityCard';

const Cards = () => {
  const [dynamicClass, setDynamicClass] = React.useState("left-1");
  const [isYearly, setIsYearly] = React.useState(true);
  // get userId and orgId from the URL
  const urlParams = new URLSearchParams(window.location.search);
  const userId = urlParams.get('userId', "");
  const orgId = urlParams.get('orgId', "");
  const toggle = () => {
    if (isYearly) {
      setDynamicClass("left-[186px]");
      setIsYearly(false);
    }
    else {
      setDynamicClass("left-1");
      setIsYearly(true);
    }
  }
  return (
    // width: 100%;
    <div className="flex w-full flex-col items-center">
      <div onClick={toggle} style={{"position":"relative"}} class="mx-4 shadow cursor-pointer rounded border h-10 mt-4 flex p-1 center w-96 relative items-center bg-gray-200">
        <div
          onClick={toggle}
          style={{ "background":"#E9D9FC", "height": "30px"}}
          className={dynamicClass + " elSwitch shadow text-gray-800 flex items-center justify-center w-1/2 rounded transition-all absolute"}>
        </div>
        <div class="w-full flex justify-center">
          <div className={'tab left ' + (dynamicClass === 'left-1' ? 'active' : '')}>Annual (save 18%)</div>
        </div>
        <div class="w-full flex justify-center">
          <div className={'tab right ' + (dynamicClass === 'left-1' ? '' : 'active')}>Monthly</div>
        </div>
      </div>
      <section className='pt-8 pb-4 px-4 w-full'>
        <ul className='flex justify-evenly mx-16 mb-adjust'>
          <CommunityCard />
          <ProCard isYearly={isYearly} userId={userId} orgId={orgId} />
          <EnterpriseCard />
        </ul>
      </section>
      <div className="flex items-center">
        <h3 className="text-xl text-center mb-8 link text-gray-800">
          Join our <a href="https://bit.ly/opal-slack" className='link' target={'_blank'} underline="none"> slack<span role="img" aria-label="chat"> 💬 </span></a>, or<a href="https://calendly.com/permitio/meeting" target={'_blank'} underline="none"> schedule a meeting <span role="img" aria-label="calendar"> 📅 </span> </a>.

        </h3>
      </div>
    
    </div> 
  );
};

export default Cards;
  
    
