import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faCheckCircle } from '@fortawesome/free-regular-svg-icons';

const APP_URL =
  'https://app.permit.io?utm_source=website&utm_medium=website-pricing&utm_campaign=pricing-free';

const CommunityCard = () => {
  return (
    <li className="mt-2 shadow-video rounded-3xl overflow-hidden max-w-full w-96 xl:flex xl:flex-col">
      <div className="text-center py-7 h-28 flex items-center justify-center text-authPurple font-bold text-4xl border-b border-[#EAE5FA]">
        <h3>Community</h3>
      </div>

      <div className="pt-9 pb-12 px-8 xl:flex xl:flex-col xl:justify-between xl:flex-grow">
        <div>
          <h4 className="font-bold text-2xl opacity-50 text-center mb-7">
            FREE FOREVER
          </h4>
          <p className="text-center leading-loose text-authPurple max-w-[240px] mx-auto mb-8">
            Up to:
            <br /> <strong>1000</strong>{' '}
            <a
              className="underline"
              target="_blank"
              href="https://docs.permit.io/concepts/glossary#mau"
              rel="noreferrer"
            >
              MAU
            </a>{' '}
            , <strong>20</strong>{' '}
            <a
              className="underline"
              target="_blank"
              href="https://docs.permit.io/concepts/glossary#tenant"
              rel="noreferrer"
            >
              Tenants
            </a>
          </p>
          <div disabled className="flex justify-center mt-16">
          <button
            disabled
            target="_blank"
            rel="noopener noreferrer"
            className="bg-authPurple mt-3 disabled w-60 text-[#EFF1F4] py-3 px-8 min-w-[12rem] text-center rounded-full font-bold glow-on-hover"
          >
            Community Plan
          </button>
        </div>

          <h4 className="font-bold text-sm mt-9 mb-3">Everything you need for:</h4>
          <ul className="grid grid-cols-1 gap-3">
            <li className="flex justify-start items-start text-sm">
              <FontAwesomeIcon
                className="text-authPurple mr-3 w-[14px] h-[14px] mt-[2px]"
                icon={faCheckCircle}
              />
              <p>Authorization API</p>
            </li>
            <li className="flex justify-start items-start text-sm">
              <FontAwesomeIcon
                className="text-authPurple mr-3 w-[14px] h-[14px] mt-[2px]"
                icon={faCheckCircle}
              />
              <p>
              Multiple models (
                <a
                  className="underline"
                  target="_blank"
                  href="https://docs.permit.io/features/policy-editor/rbac/overview"
                  rel="noreferrer"
                >
                  RBAC
                </a>
                ,{" "}
                <a
                  className="underline"
                  target="_blank"
                  href="https://docs.permit.io/how-to/build-policies/rebac/overview"
                  rel="noreferrer"
                >
                  ReBAC
                </a>
                ,{" "}
                <a
                  className="underline"
                  target="_blank"
                  href="https://docs.permit.io/features/policy-editor/abac/overview"
                  rel="noreferrer"
                >
                  ABAC
                </a>
                )
                </p>
            </li>
            <li className="flex justify-start items-start text-sm">
              <FontAwesomeIcon
                className="text-authPurple mr-3 w-[14px] h-[14px] mt-[2px]"
                icon={faCheckCircle}
              />
              <p>Authorization local/hosted microservice</p>
            </li>
            <li className="flex justify-start items-start text-sm">
              <FontAwesomeIcon
                className="text-authPurple mr-3 w-[14px] h-[14px] mt-[2px]"
                icon={faCheckCircle}
              />
              <p>
                Backoffice: policy editor, user management, project management,
                multi-tenancy
              </p>
            </li>
            <li className="flex justify-start items-start text-sm">
              <FontAwesomeIcon
                className="text-authPurple mr-3 w-[14px] h-[14px] mt-[2px]"
                icon={faCheckCircle}
              />
              <p>
                {' '}
                <a
                  className="underline"
                  target="_blank"
                  href="https://docs.permit.io/features/permit-elements/overview"
                  rel="noreferrer"
                >
                  Permit Elements
                </a>{' '}
                - Embeddable interfaces (e.g. user management)
              </p>
            </li>
          </ul>
        </div>
      </div>
    </li>
  );
};

export default CommunityCard;