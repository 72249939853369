import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import {
  faPlusSquare,
  faArrowAltCircleRight,
} from '@fortawesome/free-regular-svg-icons';

const APP_URL =
  'https://app.permit.io?utm_source=website&utm_medium=website-pricing&utm_campaign=pricing-enterprise';

const EnterpriseCard = () => {
  return (
    <li className="mt-2 shadow-video rounded-3xl overflow-hidden max-w-full w-96 xl:flex xl:flex-col">
      <div className="text-center h-28 flex items-center justify-center py-7 text-authPurple font-bold text-4xl border-b border-[#EAE5FA]">
        <h3>Enterprise</h3>
      </div>

      <div className="pt-9 pb-12 px-8 xl:flex-col xl:justify-between xl:flex-grow">
        <div>
          <h4 className="font-bold text-2xl opacity-50 text-center mt-24 mb-2">
            Let&apos;s talk
          </h4>
          <div className="flex justify-center" style={{"marginTop": '73px'}}>
          <button
            href="https://docs.google.com/forms/d/e/1FAIpQLScJPNfTasF67H0zS8G8qPoiGDC50EC-U2XFAw48zO-pWv8LjQ/viewform"
            onClick={() => {
              window.open(
                'https://docs.google.com/forms/d/e/1FAIpQLScJPNfTasF67H0zS8G8qPoiGDC50EC-U2XFAw48zO-pWv8LjQ/viewform',
                '_blank'
              );
            }
            }
            target="_blank"
            className="bg-authPurple text-[#EFF1F4] py-3 w-60 px-8 min-w-[12rem] text-center rounded-full font-bold glow-on-hover"
          >
            Contact Permit Team
          </button>
        </div>
          <p className="font-bold text-sm mt-9 mb-3">
            Features in Pro plus your choices from:
          </p>

          <ul className="grid grid-cols-1 gap-3 overflow-auto" style={{"maxHeight":"80%", "height":"35%"}}>
            <li className="flex justify-start items-start text-sm">
              <FontAwesomeIcon
                className="text-authPurple mr-3 w-[14px] h-[14px] mt-[2px]"
                icon={faPlusSquare}
              />
              <p>Single Sign On</p>
            </li>
            <li className="flex justify-start items-start text-sm">
              <FontAwesomeIcon
                className="text-authPurple mr-3 w-[14px] h-[14px] mt-[2px]"
                icon={faPlusSquare}
              />
              <p>Custom uptime and support SLAs</p>
            </li>
            <li className="flex flex-col justify-start items-start text-sm">
              <div className="flex">
                <FontAwesomeIcon
                  className="text-authPurple mr-3 w-[14px] h-[14px] mt-[2px]"
                  icon={faPlusSquare}
                />
                <p>Compliance agreements</p>
              </div>
              <ul className="list-disc pl-12 grid grid-cols-1 gap-2 mt-2">
                <li>SOC2 Report</li>
                <li>HIPAA BAA Agreement</li>
                <li>GDPR agreement & hosting</li>
              </ul>
            </li>
            <li className="flex justify-start items-start text-sm">
              <FontAwesomeIcon
                className="text-authPurple mr-3 w-[14px] h-[14px] mt-[2px]"
                icon={faPlusSquare}
              />
              <p>Professional Services </p>
            </li>
            <li className="flex justify-start items-start text-sm">
              <FontAwesomeIcon
                className="text-authPurple mr-3 w-[14px] h-[14px] mt-[2px]"
                icon={faPlusSquare}
              />
              <p>
                Advanced Deployment Options <br /> (e.g. different regions,
                multi-cloud, ...)
              </p>
            </li>
            <li className="flex justify-start items-start text-sm">
              <FontAwesomeIcon
                className="text-authPurple mr-3 w-[14px] h-[14px] mt-[2px]"
                icon={faPlusSquare}
              />
              <p>
                <a
                  className="underline"
                  href="https://docs.permit.io/tutorials/deploying/on-prem"
                  target="_blank"
                  rel="noreferrer"
                >
                  On-Prem
                </a>{' '}
                Deployment Options
              </p>
            </li>
            <li className="flex justify-start items-start text-sm">
              <FontAwesomeIcon
                className="text-authPurple mr-3 w-[14px] h-[14px] mt-[2px]"
                icon={faArrowAltCircleRight}
              />
              <p>Anomaly detection and UBA (Early Access)</p>
            </li>
          </ul>
        </div>


      </div>
    </li>
  );
};

export default EnterpriseCard;